.page-sticky-footer {
  .page-sticky-footer__left {
    float: $ldirection;
    .node-elc-nodeblock {
      float: $ldirection;
      &.special-offers-elc-nodeblock {
        border-#{$ldirection}: none;
      }
    }
  }
}
